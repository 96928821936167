.custom-status {
    position: absolute;
    top: -5px; 
    left: 135px; 
    background-color: rgba(0, 0, 0, 0.1);
    animation: thinkingAnimation 1.5s infinite ease-in-out;
    color: #ffffff; 
    border-radius: 8px; 
    padding: 6px 12px; 
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); 
    backdrop-filter: blur(8px);
    padding: 5px 10px;
    min-width: 175px;
    border-radius: 10px;
    z-index: 10; 
    white-space: nowrap;
}
.custom-status-text {
    margin-left: 5px;
}
@keyframes thinkingAnimation {
    0%, 100% {
      background-color: rgba(0, 0, 0, 0.1);
    }
    50% {
      background-color: rgba(0, 0, 0, 0.6);
    }
}
.thinking-indicator {
    margin-top: 23px;
    width: 20px; 
    height: 20px; 
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); 
    backdrop-filter: blur(8px);
    margin-left: 3px;
    margin-right: 10px; 
    animation: thinkingAnimation 1.5s infinite ease-in-out;
  }

  @media(max-width: 479px) {
    .custom-status {
        top: -15px; 
        max-width: 90px;
        white-space: inherit;
    }
  }

.loadingStatus text {
  color: white;
}
