.home-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
  transition: transform 0.3s ease; 
  transform-style: preserve-3d; 
  padding-bottom: 10px;
  padding-top: 10px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-animation {
  background-size: 200% 200%;
  animation: gradient 2s ease infinite;
}

.cursor-follow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background: radial-gradient(circle, rgba(141, 165, 201, 0.4), transparent 80%);
  background-size: 150% 150%;
  transition: background-position 0.03s ease;
  z-index: 0;
}
.tooltip {
  visibility: hidden; 
  position: absolute;
  bottom: -25px; 
  left: 50%;
  transform: translateX(-50%); 
  background-color: rgba(0, 0, 0, 0.5); 
  text-shadow: 0 0 6px rgba(255, 255, 255, 1); 
  color: white;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 13px;
  white-space: nowrap; 
  opacity: 0; 
  transition: opacity 0.3s ease-in-out; 
}
.audio-visualizer {
  height: 20px;
  display: flex;
  gap: 3px;
  position: fixed;
  opacity: 0;
}
.bar {
  width: 3px;
  background-color: white;
  transition: height 0.1s ease;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)); 
}
.audio-des {
  width: 100%;
  height: 50px;
  top: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  position: fixed;
}
.fallingFlower {
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 1));
}
.viewCounter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  position: fixed; 
  top: 10px; 
  right: 20px; 
  z-index: 10; 
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
}
.viewCounter:hover .tooltip { 
  opacity: 1;
  visibility: visible;
}
.heart-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.heart-icon {
  margin-right: 7px;
  width: 20px;
  height: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 1)); 
}
.heart-button .heart-icon { 
  fill: white; 
  transition: fill 0.3s ease; 
}
.heart-button.liked .heart-icon { 
  fill: rgb(255, 144, 162);
  filter: drop-shadow(0px 0px 2px rgba(255, 144, 162, 1));
}
.viewIcon {
  margin-right: 7px;
  width: 21px;
  height: auto;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 1)); 
}
.viewText {
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 17px;
  text-shadow: 0 0 6px rgba(255, 255, 255, 1); 
}
.preload {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12; 
  display: flex;
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.5s ease; 
}
.preload.hidden {
  opacity: 0; 
  pointer-events: none; 
}
.preload .text {
  font-size: 40px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 0 30px rgba(255, 255, 255, 1); 
}

.bg-main {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  position: fixed; 
  background-attachment: fixed;
  background-size: cover;
  object-fit: cover;
  z-index: -1; 
  background-repeat: no-repeat;
  /*filter: blur(2px);*/
  background-color: rgba(0, 0, 0, 0.1);
  background-blend-mode: darken;
}

.home-container11 {
  width: 783px;
  height: 721px;
  display: flex;
  position: relative;
  box-shadow: 2px 3px 5px 2px rgba(0, 0, 0, 0.1);
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #D43441;
}
.home-image2 {
  width: 100%;
  height: 100%;
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 151px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: flex-end;
  background-color: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-image1 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 151px;
  object-fit: cover;
}
.home-container13 {
  top: 170px;
  flex: 0 0 auto;
  left: 160px;
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text100 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  font-family: "Lexend";
  font-weight: 500;
}
.home-text101 {
  fill: #9BB9E4;
  color: #f3f4f9;
  font-style: normal;
  margin-top: 4px;
  font-weight: 400;
}
.home-container14 {
  width: 95%;
  height: 472px;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 2px 0px #ffffff;
  align-items: center;
  padding-top: 0px;
  padding-left: 20px;
  border-radius: 12px;
  margin-bottom: var(--dl-space-space-unit);
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-start;
  background-color: #ffffff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.home-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-color: #D43441;
  border-width: 1px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.home-text104 {
  color: rgb(212, 52, 65);
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
}
.home-container16 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  max-height: 420px;
  overflow-x: hidden;
  align-items: flex-start;
  flex-direction: column;
}
.home-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text105 {
  fill: #D43441;
  color: #D43441;
  font-size: 17px;
  align-self: flex-start;
  margin-bottom: 10px;
}
.home-text108 {
  color: #FF7872;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
}
.home-container18 {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text109 {
  fill: #D43441;
  color: rgb(212, 52, 65);
  font-size: 17px;
  align-self: flex-start;
  margin-bottom: 10px;
}
.home-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.home-link1 {
  display: contents;
}
.home-container20 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.home-container20:hover {
  background-color: #FFA5A1;
}
.home-container20:active {
  margin-top: var(--dl-space-space-halfunit);
}
.home-container21 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
}
.home-image2 {
  width: 30px;
  object-fit: cover;
}
.home-text112 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.home-icon1 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
  margin-right: var(--dl-space-space-halfunit);
}
.home-link2 {
  display: contents;
}
.home-container22 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  transition: 0.1s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.home-container22:hover {
  background-color: #FFA5A1;
}
.home-container22:active {
  margin-top: var(--dl-space-space-halfunit);
}
.home-container23 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.home-image3 {
  width: 24px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
}
.home-text113 {
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.home-icon3 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
  margin-right: var(--dl-space-space-halfunit);
}
.home-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text114 {
  fill: #D43441;
  color: rgb(212, 52, 65);
  font-size: 17px;
  align-self: flex-start;
  margin-bottom: 10px;
}
.home-container25 {
  width: 100%;
  height: 382px;
  display: flex;
  align-self: center;
  max-height: 382px;
  overflow-x: hidden;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: flex-start;
  border-color: #D43441;
  border-style: dashed;
  border-width: 2px;
  justify-content: center;
  scrollbar-width: none;
}
.home-container15::-webkit-scrollbar {
  width: 0 !important;
  display: none; 
}
.home-text117 {
  fill: #FF7872;
  color: #FF7872;
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text120 {
  fill: #FF7872;
  color: #FF7872;
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text121 {
  fill: #FF7872;
  color: #FF7872;
  display: inline-block;
}
.home-text122 {
  fill: #FF7872;
  color: #FF7872;
  display: inline-block;
}
.home-text123 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text126 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text129 {
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text130 {
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text131 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text134 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text137 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text140 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text143 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text146 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text149 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text152 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text155 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text158 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text161 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text164 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text167 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text170 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text173 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text176 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text179 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text182 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text185 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text188 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text191 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text194 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text197 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text200 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text203 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text206 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text209 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text212 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text215 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-text218 {
  fill: #D43441;
  color: rgb(255, 120, 114);
  display: inline-block;
}
.home-container26 {
  top: 115px;
  flex: 0 0 auto;
  left: 20px;
  width: 132px;
  height: 132px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  background-color: #D43441;
}
.profileEffect {
  position: fixed;
  height: 400px;
  width: 450px;
  object-fit: cover;
  object-position: 0 0;
}
.avatar-and-status {
  top: 123px;
  left: 25px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  display: flex;
}
.avatar_decorations {
  width: auto;
  height: 120px;
  position: fixed;
  z-index: 10;
  filter: drop-shadow(0px 0px 5px rgba(212, 52, 65, 0.7)); 
}
.home-image4 {
  width: auto;
  height: 120px;
  border-radius: var(--dl-radius-radius-round);
}
@media(max-width: 767px) {
  .home-container11 {
    width: 732px;
    height: 746px;
  }
  .home-container17 {
    width: 96%;
    align-self: center;
  }
  .home-text108 {
    text-align: left;
  }
  .home-container18 {
    width: 96%;
    align-self: center;
  }
  .home-container24 {
    width: 96%;
    align-self: center;
  }
  .home-container25 {
    width: 96%;
    padding: 5px;
  }
}
@media(max-width: 479px) {
  .homePage { 
    display: flex;         
    justify-content: center; 
  }
  .viewCounter {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    position: fixed;
    top: 0px;
    left: auto;
    right: auto;
    width: 65px;
    z-index: 100;
    transition: width 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), 
    margin-top 0.2s ease-in-out, 
    border-radius 0.2s ease-in-out; 
  }
  .heart-button {
    position: fixed;
    right: 15px;
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 1.2em;
    opacity: 0;  
    transition: opacity 0.2s ease-in-out; 
  }
  .audio-visualizer {
    left: 15px; 
    transition: opacity 0.2s ease-in-out; 
  }
  .viewCounter:hover .heart-button { 
    opacity: 1;
    visibility: visible;
  }
  .viewCounter:hover .audio-visualizer {
    opacity: 1;
    visibility: visible;
  }
  .heart-icon {
    width: 16px;
  }
  .viewCounter:hover {
    margin-top: 6px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: calc(100% - 65px); 
  }
  .viewIcon {
    width: 16px;
  }
  .viewText {
    font-size: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .home-container11 {
    width: 95%;
    height: 880px;
  }
  .home-container13 {
    top: 160px;
    left: 150px;
  }
  .home-container14 {
    height: 628px;
  }
  .home-container16 {
    max-height: 570px;
    width: 100%;
  }
  .home-container17 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-container19 {
    flex-direction: column;
  }
  .home-container20 {
    width: 100%;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-container22 {
    width: 100%;
  }
  .home-text129 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .home-container26 {
    left: 15px;
  }
  .avatar-and-status {
    left: 20px;
  }
}
