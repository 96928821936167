.animated_gif {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 0;
}

.snowman {
    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)); 
    position: fixed;
    left: 15px;
    bottom: 2px;
    width: auto;
    height: 300px;
}

.tree {
    filter: drop-shadow(0px 0px 5px rgba(133, 196, 81, 1)); 
    position: fixed;
    right: 15px;
    bottom: 5px;
    width: auto;
    height: 300px;  
}