.members-container10 {
  width: auto;
  display: flex;
  position: relative;
  align-items: stretch;
  flex-direction: row;
  justify-content: center;
}
.members-container11 {
  flex: 0 0 auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.members-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
}
.members-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.members-link10 {
  display: contents;
}
.members-container14 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container14:hover {
  background-color: #FFA5A1;
}
.members-container14:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text101 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon10 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-container16 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.members-link11 {
  display: contents;
}
.members-container17 {
  margin-top: 8px;
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container17:hover {
  background-color: #FFA5A1;
}
.members-container17:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container18 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text103 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon12 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.members-container20 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.members-link12 {
  display: contents;
}
.members-container21 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container21:hover {
  background-color: #FFA5A1;
}
.members-container21:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container22 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text105 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon14 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-container23 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
}
.members-container24 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.members-link13 {
  display: contents;
}
.members-container25 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container25:hover {
  background-color: #FFA5A1;
}
.members-container25:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container26 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text107 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon16 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link14 {
  display: contents;
}
.members-container27 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container27:hover {
  background-color: #FFA5A1;
}
.members-container27:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container28 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text108 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon18 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link15 {
  display: contents;
}
.members-container29 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container29:hover {
  background-color: #FFA5A1;
}
.members-container29:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container30 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text109 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon20 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link16 {
  display: contents;
}
.members-container31 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container31:hover {
  background-color: #FFA5A1;
}
.members-container31:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container32 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text110 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon22 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link17 {
  display: contents;
}
.members-container33 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container33:hover {
  background-color: #FFA5A1;
}
.members-container33:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container34 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text111 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon24 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link18 {
  display: contents;
}
.members-container35 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container35:hover {
  background-color: #FFA5A1;
}
.members-container35:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container36 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text112 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon26 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link19 {
  display: contents;
}
.members-container37 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container37:hover {
  background-color: #FFA5A1;
}
.members-container37:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container38 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text113 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon28 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link20 {
  display: contents;
}
.members-container39 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container39:hover {
  background-color: #FFA5A1;
}
.members-container39:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container40 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text114 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon30 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link21 {
  display: contents;
}
.members-container41 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container41:hover {
  background-color: #FFA5A1;
}
.members-container41:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container42 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text115 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon32 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link22 {
  display: contents;
}
.members-container43 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container43:hover {
  background-color: #FFA5A1;
}
.members-container43:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container44 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text116 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon34 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link23 {
  display: contents;
}
.members-container45 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container45:hover {
  background-color: #FFA5A1;
}
.members-container45:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container46 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text117 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon36 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link24 {
  display: contents;
}
.members-container47 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container47:hover {
  background-color: #FFA5A1;
}
.members-container47:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container48 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text118 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon38 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link25 {
  display: contents;
}
.members-container49 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container49:hover {
  background-color: #FFA5A1;
}
.members-container49:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container50 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text119 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon40 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link26 {
  display: contents;
}
.members-container51 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container51:hover {
  background-color: #FFA5A1;
}
.members-container51:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container52 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text120 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon42 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-container53 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.members-link27 {
  display: contents;
}
.members-container54 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container54:hover {
  background-color: #FFA5A1;
}
.members-container54:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container55 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text122 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon44 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link28 {
  display: contents;
}
.members-container56 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container56:hover {
  background-color: #FFA5A1;
}
.members-container56:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container57 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text123 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon46 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link29 {
  display: contents;
}
.members-container58 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container58:hover {
  background-color: #FFA5A1;
}
.members-container58:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container59 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text124 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon48 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link30 {
  display: contents;
}
.members-container60 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container60:hover {
  background-color: #FFA5A1;
}
.members-container60:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container61 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text125 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon50 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link31 {
  display: contents;
}
.members-container62 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container62:hover {
  background-color: #FFA5A1;
}
.members-container62:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container63 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text126 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon52 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link32 {
  display: contents;
}
.members-container64 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container64:hover {
  background-color: #FFA5A1;
}
.members-container64:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container65 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text127 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon54 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link33 {
  display: contents;
}
.members-container66 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container66:hover {
  background-color: #FFA5A1;
}
.members-container66:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container67 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text128 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon56 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link34 {
  display: contents;
}
.members-container68 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container68:hover {
  background-color: #FFA5A1;
}
.members-container68:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container69 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text129 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon58 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link35 {
  display: contents;
}
.members-container70 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container70:hover {
  background-color: #FFA5A1;
}
.members-container70:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container71 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text130 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon60 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link36 {
  display: contents;
}
.members-container72 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container72:hover {
  background-color: #FFA5A1;
}
.members-container72:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container73 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text131 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon62 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link37 {
  display: contents;
}
.members-container74 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container74:hover {
  background-color: #FFA5A1;
}
.members-container74:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container75 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text132 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon64 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link38 {
  display: contents;
}
.members-container76 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container76:hover {
  background-color: #FFA5A1;
}
.members-container76:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container77 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text133 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon66 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link39 {
  display: contents;
}
.members-container78 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container78:hover {
  background-color: #FFA5A1;
}
.members-container78:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container79 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text134 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon68 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link40 {
  display: contents;
}
.members-container80 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container80:hover {
  background-color: #FFA5A1;
}
.members-container80:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container81 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text135 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon70 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link41 {
  display: contents;
}
.members-container82 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container82:hover {
  background-color: #FFA5A1;
}
.members-container82:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container83 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text136 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon72 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-link42 {
  display: contents;
}
.members-container84 {
  flex: 0 0 auto;
  width: 322px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  align-items: center;
  border-color: #D43441;
  border-style: solid;
  border-width: 2px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
  text-decoration: none;
}
.members-container84:hover {
  background-color: #FFA5A1;
}
.members-container84:active {
  margin-top: var(--dl-space-space-halfunit);
}
.members-container85 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.members-text137 {
  fill: #FF7872;
  color: #FF7872;
  margin-left: var(--dl-space-space-halfunit);
}
.members-icon74 {
  fill: #FF7872;
  color: #FF7872;
  align-self: center;
}
.members-text138 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text141 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text144 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text147 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text150 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text153 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text156 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text159 {
  fill: #7782BE;
  color: #7782BE;
  display: inline-block;
}
.members-text160 {
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text161 {
  fill: #7782BE;
  color: #7782BE;
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.members-text162 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text165 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text168 {
  fill: #7782BE;
  color: #7782BE;
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.members-text171 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text174 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text177 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text180 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text183 {
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text184 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text187 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text190 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text193 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text196 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text199 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text202 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text205 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text208 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
  margin-bottom: var(--dl-space-space-halfunit);
}
.members-text211 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text214 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text217 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text220 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text223 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text226 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text229 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text232 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text235 {
  fill: #7782BE;
  color: #7782BE;
  display: inline-block;
}
.members-text236 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.members-text239 {
  fill: #7782BE;
  color: rgb(119, 130, 190);
  display: inline-block;
}
.membersroot-class-name {
  height: auto;
  margin-top: var(--dl-space-space-halfunit);
  max-height: auto;
}
@media(max-width: 767px) {
  .members-container11 {
    width: 100%;
  }
  .membersroot-class-name {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .members-container12 {
    width: 285px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .members-container13 {
    width: 285px;
    margin-right: 0px;
  }
  .members-container14 {
    width: 100%;
  }
  .members-container16 {
    width: 285px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .members-container17 {
    width: 100%;
  }
  .members-container19 {
    width: 285px;
  }
  .members-container20 {
    width: 100%;
  }
  .members-container21 {
    width: 100%;
  }
  .members-container23 {
    width: 285px;
    flex-direction: column;
  }
  .members-container24 {
    width: 100%;
    margin-right: 0px;
  }
  .members-container25 {
    width: 100%;
  }
  .members-container27 {
    width: 100%;
  }
  .members-container29 {
    width: 100%;
  }
  .members-container31 {
    width: 100%;
  }
  .members-container33 {
    width: 100%;
  }
  .members-container35 {
    width: 100%;
  }
  .members-container37 {
    width: 100%;
  }
  .members-container39 {
    width: 100%;
  }
  .members-container41 {
    width: 100%;
  }
  .members-container43 {
    width: 100%;
  }
  .members-container45 {
    width: 100%;
  }
  .members-container47 {
    width: 100%;
  }
  .members-container49 {
    width: 100%;
  }
  .members-container51 {
    width: 100%;
  }
  .members-container53 {
    width: 285px;
  }
  .members-container54 {
    width: 100%;
  }
  .members-container56 {
    width: 100%;
  }
  .members-container58 {
    width: 100%;
  }
  .members-container60 {
    width: 100%;
  }
  .members-container62 {
    width: 100%;
  }
  .members-container64 {
    width: 100%;
  }
  .members-container66 {
    width: 100%;
  }
  .members-container68 {
    width: 100%;
  }
  .members-container70 {
    width: 100%;
  }
  .members-container72 {
    width: 100%;
  }
  .members-container74 {
    width: 100%;
  }
  .members-container76 {
    width: 100%;
  }
  .members-container78 {
    width: 100%;
  }
  .members-container80 {
    width: 100%;
  }
  .members-container82 {
    width: 100%;
  }
  .members-container84 {
    width: 100%;
  }
  .members-text160 {
    margin-top: var(--dl-space-space-halfunit);
  }
}
